<template>
  <div class="mx-4 xl:container xl:mx-auto mx-auto flex flex-col text-white p-4 h-full">
    <BreadCrumbs />
    <div class="flex flex-wrap flex-col h-full content-between justify-around relative">
      <div class="flex flex-wrap justify-center">
        <ImageBlock
            class="my-2 mx-3"
            v-for="element in elements"
            :key="element.id"
            :id="element.id"
            :title="element.title"
            :path="element.path"
        />
      </div>
      <div class="flex flex-row justify-between">
        <TextButton class="w-full sm:w-fit mx-2" text="Что было раньше?"/>
        <TextButton class="w-full sm:w-fit mx-2" text="Что было дальше?"/>
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import ImageBlock from "@/components/ImageBlock";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
  name: "GalleryPage",
  components: {ImageBlock, BreadCrumbs, TextButton},

  data() {
    return {
      elements: [
        {
          id: 1,
          title: "Какой-то текст",
          path: require('@/assets/images/img1.jpg')
        },
        {
          id: 2,
          title: "Какой-то текст",
          path: require('@/assets/images/img2.jpg')
        },
        {
          id: 3,
          title: "Какой-то текст",
          path: require('@/assets/images/img3.jpg')
        },
        {
          id: 4,
          title: "Какой-то текст",
          path: require('@/assets/images/img4.jpg')
        },
        {
          id: 5,
          title: "Какой-то текст",
          path: require('@/assets/images/img5.jpg')
        },
        {
          id: 6,
          title: "Какой-то текст",
          path: require('@/assets/images/img6.jpg')
        }
      ]
    }
  },

  methods: {}
}
</script>

<style scoped>

</style>
