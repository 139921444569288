<template>
  <div class="flex flex-col uppercase text-white text-sm border rounded-md cursor-pointer hover:border-orange-600 hover:text-orange-600 w-80 h-56">
    <div class="rounded-md">
      <img class="rounded-md" alt="image" :src="path" />
    </div>
    <div class="px-2">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBlock",
  props: {
    id: Number,
    path: String,
    title: String
  }
}
</script>

<style scoped>

</style>