<template>
  <div class="mx-4 xl:container xl:mx-auto mx-auto flex flex-col text-white p-4 h-full">
    <BreadCrumbs />
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
  name: "EarthPage",
  components: {BreadCrumbs}
}
</script>

<style scoped>

</style>
