<template>
  <div class="relative uppercase text-base border rounded-md py-1 px-4 mt-2 mb-3"
       :class="{
          'hover:border-orange-600 hover:text-orange-600 cursor-pointer' : !isDisabled,
          'text-gray-800 border-gray-800' : isDisabled,
          'active-button' : isSelected
       }"
       v-on:mouseover="isOpen = true"
       v-on:mouseleave="isOpen = false">
    <span>{{ title }}</span>
    <div v-if="children && children.length"
         class="absolute w-64 rounded-md bg-black z-10 top-6 left-0 pl-4 pr-2 py-2 mt-2.5 "
         :class="{ 'visible': isOpen , 'hidden': !isOpen  }"
         v-on:mouseover="isOpen = true"
         v-on:mouseleave="isOpen = false"
    >
      <div class="text-white hover:text-orange-600"
           v-for="elem in children"
           :key="elem.id"
           @click="setTime(elem)">
        <span>{{ elem.title }}</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SelectableBlock",
  props: {
    id: Number,
    text: String,
    isDisabled: Boolean,
    isSelected: Boolean,
    children: Array
  },
  data() {
    return {
      isOpen: false,
      title: null,
      selectedId: null
    }
  },
  created() {
    this.title = this.text;
  },
  methods: {
    setTime: function (elem) {
      this.title = elem.title;
      this.selectedId = elem.id;
      this.isOpen = false;

      this.$emit("selectElement", this.selectedId);
    }
  }
}
</script>

<style scoped>
.active-button {
  background: radial-gradient(50% 50% at 50% 50%, #052DC9 0%, rgba(5, 45, 201, 0.5) 57.08%, rgba(238, 45, 1, 0.01) 100%);
}
</style>
