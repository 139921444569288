<template>
  <div class="relative uppercase text-base border rounded-md py-1 px-4 mt-2 mb-3"
       :class="{
          'hover:border-orange-600 hover:text-orange-600 cursor-pointer' : !isDisabled,
          'text-gray-800 border-gray-800' : isDisabled
       }">
    <span>{{ title }}</span>
  </div>

</template>

<script>
export default {
  name: "TextButton",
  props: {
    id: Number,
    text: String,
    isDisabled: Boolean,
  },
  data() {
    return {
      isOpen: false,
      title: null
    }
  },
  created() {
    this.title = this.text;
  },
  methods: {
    setTime: function (time) {
      this.title = time;
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>
</style>
