<template>
  <div class="bg-black w-screen sm:h-screen min-h-screen">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
</style>