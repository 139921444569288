import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage";
import GalleryPage from "@/views/GalleryPage";
import VideoPage from "@/views/VideoPage";
import ArticleListPage from "@/views/ArticleListPage";
import ArticlePage from "@/views/ArticlePage";
import EarthPage from "@/views/EarthPage";
import EarthDescription from "@/views/EarthDescription";
import ReliefListPage from "@/views/ReliefListPage";
import ReliefPage from "@/views/ReliefPage";
import ExhibitPage from "@/views/ExhibitPage";


const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/gallery",
        name: "GalleryPage",
        component: GalleryPage,
    },
    {
        path: "/video",
        name: "VideoPage",
        component: VideoPage,
    },
    {
        path: "/articles",
        name: "ArticleListPage",
        component: ArticleListPage,
    },
    {
        path: "/article",
        name: "ArticlePage",
        component: ArticlePage,
    },
    {
        path: "/earth",
        name: "EarthPage",
        component: EarthPage,
    },
    {
        path: "/earth-info",
        name: "EarthDescription",
        component: EarthDescription,
    },
    {
        path: "/relief",
        name: "ReliefListPage",
        component: ReliefListPage,
    },
    {
        path: "/relief-info",
        name: "ReliefPage",
        component: ReliefPage,
    },
    {
        path: "/exhibit",
        name: "ExhibitPage",
        component: ExhibitPage,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
