<template>
  <div class="text-white uppercase mb-4">
    <router-link to="/">Главная</router-link>
    <div class="inline-block">&nbsp;/ Время:
      <div class="inline-block relative cursor-pointer"
           @mouseover="showTimes = true"
           @mouseout="showTimes = false">{{ getTime() }}

        <svg :class="{'rotate-180' : showTimes }"
             class="inline-block" width="10" height="6" viewBox="0 0 10 6" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5 5L9 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div :class="{ 'visible': showTimes , 'hidden': !showTimes  }"
             class="absolute w-64 rounded-md bg-black z-10 top-5 -left-1 p-1 mt-0.5"
             @mouseover="showTimes = true"
             @mouseout="showTimes = false">
          <div class="relative text-white hover:text-orange-600"
               :key="time.id"
               v-for="time in timeline">
            <div @click="changeLocation(time.id, instrumentId)"
                 @mouseover="time.show = true"
                 @mouseout="time.show = false"
            >{{ time.title }}
              <svg v-if="time.children && time.children.length"
                   :class="{'rotate-180 sm:-rotate-90' : time.show }"
                   class="inline-block" width="10" height="6" viewBox="0 0 10 6" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>

            <div v-if="time.children && time.children.length"
                 :class="{ 'visible': time.show , 'hidden': !time.show  }"
                 class="absolute w-64 rounded-md bg-black z-10 top-5 sm:-top-1.5 sm:left-48 pl-3 p-1 mt-0.5"
                 @mouseover="time.show = true"
                 @mouseout="time.show = false">
              <div
                  class="text-white hover:text-orange-600"
                  v-for="subtime in time.children"
                  :key="subtime.id">
                <div @click="changeLocation(subtime.id, instrumentId)">{{ subtime.title }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="inline-block">
      &nbsp;/ Инструмент:
      <div class="inline-block relative cursor-pointer"
           @mouseover="showInstruments = true"
           @mouseout="showInstruments = false">{{ getInstrument() }}

        <svg :class="{'rotate-180' : showInstruments }"
             class="inline-block"
             width="10" height="6" viewBox="0 0 10 6"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5 5L9 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div :class="{ 'visible': showInstruments , 'hidden': !showInstruments  }"
             class="absolute w-32 rounded-md bg-black z-10 top-5 -left-1 p-1 mt-0.5"
             @mouseover="showInstruments = true"
             @mouseout="showInstruments = true">
          <div class="text-white hover:text-orange-600"
               :key="instrument.id"
               v-for="instrument in instruments">
            <div @click="changeLocation(timeId, instrument.id)">{{ instrument.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="inline-block" v-if="subsection">&nbsp;/ {{ subsection }}</div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  props: {
    subsection: String
  },
  created() {
    this.timeId = (this.$route.query.timeId ? parseInt(this.$route.query.timeId) : 1);
    switch (this.$route.name) {
      case "VideoPage":
        this.instrumentId = 1;
        break;
      case "ArticleListPage":
        this.instrumentId = 2;
        break;
      case "GalleryPage":
        this.instrumentId = 3;
        break;
      case "ReliefListPage":
        this.instrumentId = 4;
        break;
      case "EarthPage":
        this.instrumentId = 5;
        break;
      default:
        this.instrumentId = 1;
    }
  },
  data() {
    return {
      timeId: 1,
      instrumentId: 1,
      showInstruments: false,
      showTimes: false,
      timeline: [
        {
          id: 1,
          title: "Большой взрыв",
          children: []
        },
        {
          id: 2,
          title: "Солнечная система",
          children: []
        },
        {
          id: 3,
          title: "Образование Луны",
          children: []
        },
        {
          id: 4,
          title: "История Земли",
          children: [
            {
              id: 5,
              title: "Чёрная Земля"
            },
            {
              id: 6,
              title: "Голубая Земля"
            },
            {
              id: 7,
              title: "Серая Земля"
            },
            {
              id: 8,
              title: "Живая Земля"
            },
            {
              id: 9,
              title: "Красная Земля"
            },
            {
              id: 10,
              title: "Скучный миллиард"
            },
            {
              id: 11,
              title: "Белая Земля"
            },
            {
              id: 12,
              title: "Зелёная Земля"
            },
            {
              id: 13,
              title: "Эпоха человека"
            },
            {
              id: 14,
              title: "Настоящее"
            },
            {
              id: 15,
              title: "Будущее"
            }
          ]
        }
      ],
      instruments: [
        {
          id: 1,
          title: "Видео"
        },
        {
          id: 2,
          title: "Статьи"
        },
        {
          id: 3,
          title: "Галерея"
        },
        {
          id: 4,
          title: "Рельеф"
        },
        {
          id: 5,
          title: "3D-Земли"
        },
      ]
    }
  },
  methods: {
    getTime: function () {
      const time = this.findById(this.timeline, this.timeId);
      if (time) {
        return time.title;
      } else {
        return null;
      }
    },

    getInstrument: function () {
      return this.instruments.find(x => x.id === this.instrumentId).title;
    },

    findById: function (data, id) {
      function iter(a) {
        if (a.id === id) {
          result = a;
          return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
      }

      let result;
      data.some(iter);
      return result
    },

    changeLocation: function (timeId, instrumentId) {
      const time = this.findById(this.timeline, timeId);

      if (!(time && time.children && time.children.length)) {
        this.showTimes = false;
        this.showInstruments = false;

        this.timeId = timeId;

        switch (instrumentId) {
          case 1:
            this.$router.push({
              name: "VideoPage",
              query: {
                timeId: timeId
              }
            });
            break;
          case 2:
            this.$router.push({
              name: "ArticleListPage",
              query: {
                timeId: timeId
              }
            });
            break;
          case 3:
            this.$router.push({
              name: "GalleryPage",
              query: {
                timeId: timeId
              }
            });
            break;
          case 4:
            this.$router.push({
              name: "ReliefListPage",
              query: {
                timeId: timeId
              }
            });
            break;
          case 5:
            this.$router.push({
              name: "EarthPage",
              query: {
                timeId: timeId
              }
            });
            break;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
