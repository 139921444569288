<template>
  <div class="mx-4 xl:container xl:mx-auto mx-auto flex flex-col text-white p-4 h-full">
    <BackgroundImage/>
    <div class="text-4xl uppercase font-extralight relative break-words">История вселенной</div>

    <div class="flex flex-wrap flex-col h-full content-between justify-around relative">
      <div class="flex flex-row">
        <div class="text-2xl uppercase my-2 w-12">01</div>
        <div class="text-2xl uppercase my-2 break-words">Выбирай время</div>
      </div>

      <div class="flex flex-wrap sm:ml-11">
        <SelectableBlock
            class="w-full sm:w-fit mx-2"
            :class="{}"
            v-for="element in timeline"
            :key="element.id"
            :id="element.id"
            :text="element.title"
            :children="element.children"
            :is-selected="element.selected"
            @selectElement="selectElements($event)"
            @click="selectTime(element.id)"
        />
      </div>

      <div class="flex flex-row">
        <div class="text-2xl uppercase my-2 w-12">02</div>
        <div class="text-2xl uppercase my-2 break-words">Выбирай инструмент</div>
      </div>

      <div class="flex flex-wrap sm:ml-11">
        <SelectableBlock
            class="w-full sm:w-fit mx-2"
            :class="{}"
            v-for="instrument in instruments"
            :key="instrument.id"
            :id="instrument.id"
            :text="instrument.title"
            :is-selected="instrument.selected"
            :is-disabled="instrument.disabled"
            @selectElement="selectElements($event)"
            @click="selectInstrument(instrument.id)"
        />
      </div>

      <TextButton
          class="relative font-semibold text-xl sm:w-fit mt-4 mx-2 pb-4 pt-4"
          text="Изучать вселенную"
          @click="applyFilters()"/>
    </div>

    <div class="flex flex-wrap flex-row justify-between relative text-sm">
      <div>
        <span class="cursor-pointer">О проекте</span>
      </div>
      <div>
        <span class="cursor-pointer">Участники</span>
      </div>
      <div>
        <span class="cursor-pointer">Источники</span>
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import BackgroundImage from "@/components/BackgroundImage";
import SelectableBlock from "@/components/SelectableButton";

export default {
  name: "HomePage",
  components: {SelectableBlock, BackgroundImage, TextButton},
  data() {
    return {
      selectedTimeId: null,
      selectedInstrumentId: null,
      timeline: [
        {
          id: 1,
          title: "Большой взрыв",
          instruments: [1, 2, 3],
          children: []
        },
        {
          id: 2,
          title: "Солнечная система",
          instruments: [1, 2, 3],
          children: []
        },
        {
          id: 3,
          title: "Образование Луны",
          instruments: [1, 2, 3],
          children: []
        },
        {
          id: 4,
          title: "История Земли",
          instruments: [1, 2, 3, 4, 5],
          children: [
            {
              id: 5,
              title: "Чёрная Земля"
            },
            {
              id: 6,
              title: "Голубая Земля"
            },
            {
              id: 7,
              title: "Серая Земля"
            },
            {
              id: 8,
              title: "Живая Земля"
            },
            {
              id: 9,
              title: "Красная Земля"
            },
            {
              id: 10,
              title: "Скучный миллиард"
            },
            {
              id: 11,
              title: "Белая Земля"
            },
            {
              id: 12,
              title: "Зелёная Земля"
            },
            {
              id: 13,
              title: "Эпоха человека"
            },
            {
              id: 14,
              title: "Настоящее"
            },
            {
              id: 15,
              title: "Будущее"
            }
          ]
        }
      ],
      instruments: [
        {
          id: 1,
          title: "Видео",
          disabled: false
        },
        {
          id: 2,
          title: "Статьи",
          disabled: false
        },
        {
          id: 3,
          title: "Галерея",
          disabled: false
        },
        {
          id: 4,
          title: "Рельеф",
          disabled: true
        },
        {
          id: 5,
          title: "3D-Земли",
          disabled: true
        },
      ]
    }
  },
  methods: {
    selectElements: function (id) {
      this.selectedTimeId = id;
      const selectedElement = this.findById(this.timeline, this.selectedTimeId);
      const parentElement = this.findParentId(this.timeline, this.selectedTimeId);

      if (selectedElement !== undefined && parentElement !== true) {
        this.timeline.forEach((element) => element.selected = false);
        this.timeline.find(x => x.id === parentElement).title = selectedElement.title;
        this.timeline.find(x => x.id === parentElement).selected = true;
        this.timeline.find(x => x.id === parentElement).id = selectedElement.id;

        this.checkInstruments();
      }
    },

    selectTime: function (id) {
      const time = this.timeline.find(x => x.id === id);

      if (time.children && time.children.length) {
        // test
      } else {
        this.timeline.forEach((element) => element.selected = false);
        this.selectedTimeId = id;
        this.timeline.find(x => x.id === id).selected = true;

        this.checkInstruments();
      }
    },

    findById: function (data, id) {
      function iter(a) {
        if (a.id === id) {
          result = a;
          return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
      }

      let result;
      data.some(iter);
      return result
    },

    findParentId: function (arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return true;
        } else if (arr[i].children && arr[i].children.length) {
          const t = this.findParentId(arr[i].children, id);

          if (t === true) {
            return arr[i].id;
          }
        }
      }

      return null;
    },

    checkInstruments: function () {
      this.instruments.forEach((el) => el.disabled = true);
      const time = this.timeline.find(x => x.selected === true);

      if (time) {
        this.instruments.forEach(el => {
          if (time.instruments.includes(el.id)) el.disabled = false;
        });
        this.instruments.forEach(el => {
          if (el.disabled === true) el.selected = false;
          if (el.id === this.selectedInstrumentId) this.selectedInstrumentId = null;
        });
      }
    },

    selectInstrument: function (id) {
      if (this.instruments.find(x => x.id === id).disabled === false) {
        this.instruments.forEach((instrument) => instrument.selected = false);
        this.instruments.find(x => x.id === id).selected = true;

        this.selectedInstrumentId = id;
      } else {
        this.selectedInstrumentId = null;
      }
    },

    applyFilters: function () {
      let instrumentId = (this.selectedInstrumentId !== null ? this.selectedInstrumentId : 1);
      let timeId = (this.selectedTimeId !== null ? this.selectedTimeId : 1);

      switch (instrumentId) {
        case 1:
          this.$router.push({
            name: "VideoPage",
            query: {
              timeId: timeId
            }
          });
          break;
        case 2:
          this.$router.push({
            name: "ArticleListPage",
            query: {
              timeId: timeId
            }
          });
          break;
        case 3:
          this.$router.push({
            name: "GalleryPage",
            query: {
              timeId: timeId
            }
          });
          break;
        case 4:
          this.$router.push({
            name: "ReliefListPage",
            query: {
              timeId: timeId
            }
          });
          break;
        case 5:
          this.$router.push({
            name: "EarthPage",
            query: {
              timeId: timeId
            }
          });
          break;
      }

    }
  }
}
</script>

<style scoped>

</style>
